
import { Component, Vue } from "vue-property-decorator";
import { ROOT_ACTIONS } from "./store/actions";
import { ROOT_MUTATIONS } from "./store/mutations";
import { Snackbar } from "./store/types";
import { USER_ACTIONS } from "./store/user/actions";
import { User } from "./store/user/types";

@Component({
  components: {
    AppBar: () => import("@/components/layout/AppBar.vue"),
    Navigation: () => import("@/components/layout/Navigation.vue"),
  },
})
export default class App extends Vue {
  get snackbar(): Snackbar {
    return this.$store.state.snackbar;
  }
  set snackbar(value: Snackbar) {
    this.$store.commit(ROOT_MUTATIONS.TOGGLE_SNACKBAR, value);
  }

  get breadcrumbs() {
    return this.$store.state.breadcrumbs
  }

  appLoading = true;

  async created() {
    await this.$store.dispatch(USER_ACTIONS.AUTHENTICATE_USER);

    setTimeout(() => {
      this.appLoading = false;
    }, 250)

  }

  get showNavigation() {
    const excludedPaths = ['/login', '/confirm-account', '/reset-password', '/public', '/participant'];
    return !excludedPaths.some(path => this.$route.fullPath === path || this.$route.fullPath.startsWith(path));
  }


  mounted() {
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Home', target: '/' }])
  }

  get user(): User {
    return this.$store.state.user.profile;
  }
}
